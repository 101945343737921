import React from 'react';
import Helmet from 'react-helmet'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


function ResponsiveDrawer(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>ПлиткаБот - Установить на сайт</title>
      </Helmet>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" noWrap>Установить на сайт</Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Typography variant="h5">Покупка плитки онлайн</Typography>
        <Typography align="justify" paragraph>ПлиткаБот имеет специальный режим, в котором есть все функции для того чтобы купить-продать плитку удаленно онлайн. Покупатель самостоятельно рисует план помещения и выбирает дизайн. Затем прямо в приложении делает запрос продавцу.</Typography>
        <img src="/cart.png" style={{maxWidth: '100%'}} alt="Корзина" />
        <Typography align="justify" paragraph>Приложение сразу отправит сообщение продавцу по электронной почте, в котором будет специальная ссылка на ПлиткаБот. По этой ссылке в продавец может видеть нарисованный покупателем план (размеры помещения и сантехнику), выбранные бренды, коллекции, дизайны, и телефон покупателя. Продавец может просматривать всю информацию, которую подготовил покупатель перед запросом.</Typography>
        <Typography variant="h5">Поисковые системы</Typography>
        <Typography align="justify" paragraph>Поисковики для ранжирования страниц в выдаче используют поведенческие факторы. Поведенческие факторы - информация о взаимодествии пользователя с сайтом. Время проведенное на сайте - один из важных поведенческих факторов, указывает на то, что информация на сайте заинтерисовала пользователя. Установка приложения поможет увеличить время на сайте, значит будет положительно влиять на позицию сайта в поисковиках.</Typography>
        <Typography variant="h5">Варианты интеграции</Typography>
        <Typography align="justify" paragraph>Режим магазина активируется параметром <code style={{color: '#f00'}}>s</code> в адресной строке <code style={{color: '#f00'}}>http://plitkabot.ru/appx/?s=[КОД-МАГАЗИНА]</code> Для получения кода необходима регистрация в системе, пишите на почту info@plitkabot.ru</Typography>
        <Typography align="justify" paragraph>Возможно два варианта установки: встроить в страницу сайта (см. ниже пример). Или разместить на отдельной странице (см. <a href="/fullpage.html">пример</a>), чтобы приложение занимало всю страницу.</Typography>
        <Typography align="justify" paragraph>В первом варианте есть возможность задать размер, положение элемента с приложением, полностью интегрировать в дизайн своей страницы. У этого способа есть недостаток: часть экрана будут занимать элементы сайта (например: шапка, подвал), это будет добавлять неудобства на небольших экранах смартфонов.</Typography>
        <Typography align="justify" paragraph>В любом варианте приложение для пользователя выглядит как часть сайта: сохраняется адресная строка, работает кнопка назад.</Typography>
        <iframe src="https://plitkabot.ru/appx/?s=1485990730&b=lb&c=%D0%92%D0%B5%D1%81%D1%82%D0%B0%D0%BD%D0%B2%D0%B8%D0%BD%D0%B4&d=8745" style={{maxWidth:'800px', maxHeight:'600px', width:'100%', minHeight:'480px', border:'none'}} title="ПлиткаБот" />
        <Typography variant="h6">Пример html кода</Typography>
        <Typography align="justify" paragraph>Для добавления приложения на страницу.</Typography>
        <Typography align="justify" paragraph><code style={{color: '#f00'}}>&lt;iframe src=&quot;https://plitkabot.ru/appx/?s=1485990730 style=&quot;max-width:800px; max-height:600px; width:100%; min-height:320px; border:none;&quot; title=&quot;ПлиткаБот&quot; /&gt;</code></Typography>
        <Typography align="justify" paragraph>Пример html-кода, где ПлиткаБот занимает всю страницу</Typography>
        <Typography align="justify" paragraph>
            <code style={{color: '#f00'}}>
            &lt;!DOCTYPE html&gt;<br/>
            &lt;html&gt;<br/>
            &lt;head&gt;<br/>
            &nbsp;&nbsp;&lt;meta charset=&quot;utf-8&quot;&gt;<br/>
            &nbsp;&nbsp;&lt;meta name=&quot;viewport&quot; content=&quot;width=device-width, initial-scale=1, maximum-scale=1&quot;&gt;<br/>
            &nbsp;&nbsp;&lt;title&gt;Пример интеграции ПлиткаБот на всю страницу&lt;/title&gt;<br/>
            &nbsp;&nbsp;&lt;style&gt;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;html,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;body {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;height: 100%;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;width: 100%;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;margin: 0;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;padding: 0;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;overflow-y: hidden;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;overflow-x: hidden;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&lt;/style&gt;<br/>
            &lt;/head&gt;<br/>
            &lt;body&gt;<br/>
            &nbsp;&nbsp;&lt;iframe src=&quot;https://plitkabot.ru/appx/?s=1485990730&quot; style=&quot;width:100%; height:100%; border:none;&quot;&gt;&lt;/iframe&gt;<br/>
            &lt;/body&gt;<br/>
            &lt;/html&gt;<br/>       
            </code>
        </Typography>
        <br/>
        <br/>
        <Typography align="center">ПлиткаБот 2020</Typography>
        <br/>
      </Container>

    </React.Fragment>
  );
}


export default ResponsiveDrawer;
